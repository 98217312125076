import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/Auth.vue'
import Login from '../components/Auth/Login.vue'
import Register from '../components/Auth/Register.vue'
import Dash from '../views/Dash.vue'
import StartYourRaises from '../views/StartYourRaises.vue'
import VueCookies from 'vue-cookies'
import store from '../store/store.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../components/Page/AboutUs.vue')
  },
  {
    path: '/',
    component: () => import('../views/Page.vue'),
    children: [
      {
        path: 'contact-us',
        name: 'contact-us',
        component: () => import('../components/Page/ContactUs.vue')
      },
      {
        path: 'e-learning',
        name: 'e-learning',
        component: () => import('../components/Page/Elearning.vue')
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () => import('../components/Page/Faq/Faq.vue')
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../components/Page/PrivacyPolicy.vue')
      },
      {
        path: 'terms-condition',
        name: 'TermsAndConditions',
        component: () => import('../components/Auth/TermsAndConditions.vue')
      }
    ]
  },
  {
    path: '/startup/',
    component: StartYourRaises,
    children: [
      {
        name: 'StartYourRaises',
        path: 'create-startup',
        component: () => import('../components/Startup/CreateStartup.vue')
      },
      {
        name: 'OfflineSubmit',
        path: 'offline-submit',
        component: () => import('../components/Startup/StartFundRaising/Offline/SubmitOffline.vue')
      },
      {
        name: 'OnlineSubmit',
        path: 'online-submit',
        component: () => import('../components/Startup/StartFundRaising/Online/SubmitOnlineNew.vue')
      },
      {
        name: 'OnlineSubmitWh',
        path: 'online-submit-wh',
        component: () => import('../components/Startup/StartFundRaising/OnlineWh/SubmitOnlineNew.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'ErrorPageNotFound',
    props: true,
    component: () => import('../components/ErrorPage/404.vue')
  },
  {
    path: '/500',
    name: 'ErrorPageInvalid',
    props: true,
    component: () => import('../components/ErrorPage/500.vue')
  },
  {
    path: '/',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('../components/Auth/ForgotPassword.vue')
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('../components/Auth/ResetPassword.vue')
      },
      {
        path: 'verify',
        name: 'VerifyAccount',
        component: () => import('../components/Auth/VerifyAccount.vue')
      }
    ]
  },
  {
    path: '/dash/',
    component: Dash,
    children: [
      {
        path: 'profile',
        name: 'MyProfile',
        component: () => import('../components/Dash/Profile/MyProfile.vue')
      },
      {
        path: 'business',
        name: 'Business',
        component: () => import('../components/Dash/Business/Business.vue')
      },
      {
        path: 'wh-business',
        name: 'WhBusiness',
        component: () => import('../components/Dash/WhBusiness/WhBusinessList.vue')
      },
      {
        path: 'wh-business-add',
        name: 'WhBusinessAdd',
        component: () => import('../components/Dash/WhBusiness/WhBusinessAdd.vue')
      },
      {
        path: 'wh-business-edit/:pid',
        name: 'WhBusinessEdit',
        component: () => import('../components/Dash/WhBusiness/WhBusinessEdit.vue')
      },
      {
        path: 'detail-business',
        name: 'DashDetailBusiness',
        component: () => import('../components/Dash/Business/DetailBusiness.vue')
      },
      {
        path: 'investment',
        name: 'TransactionHistory',
        component: () => import('../components/Dash/Investment/TransactionHistory.vue')
      },
      {
        path: 'detail-investment',
        name: 'TransactionDetail',
        component: () => import('../components/Dash/Investment/TransactionDetail.vue')
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('../components/Dash/Settings/Settings.vue')
      },
      {
        path: 'wallet',
        name: 'Wallet',
        component: () => import('../components/Dash/Wallet/Wallet.vue')
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('../components/Dash/Dashboard/Dashboard.vue')
      },
      {
        path: 'saham',
        name: 'Shares',
        component: () => import('../components/Dash/Shares/Shares.vue')
      },
      {
        path: 'history-transaksi-secondary-market',
        name: 'HistoryTransactionSecondaryMarket',
        component: () => import('../components/Dash/SecondaryMarket/DetailSecondaryMarket.vue')
      },
      {
        path: 'history-secondary-market',
        name: 'HistorySecondaryMarket',
        component: () => import('../components/Dash/SecondaryMarket/HistorySecondaryMarket.vue')
      },
      {
        path: 'wh-assets',
        name: 'WhAssets',
        component: () => import('../components/Dash/WhAssets/WhAssetsList.vue')
      },
      {
        path: 'wh-asset-add',
        name: 'WhAssetAdd',
        component: () => import('../components/Dash/WhAssets/WhAssetAdd.vue')
      },
      {
        path: 'wh-asset-edit/:id',
        name: 'WhAssetEdit',
        component: () => import('../components/Dash/WhAssets/WhAssetEdit.vue')
      }
    ]
  },
  {
    path: '/kyc/',
    component: () => import('../views/KycParent.vue'),
    children: [
      {
        path: 'document',
        name: 'Kyc1',
        component: () => import('../components/Kyc/Kyc1.vue')
      },
      {
        path: 'terms-condition',
        name: 'Kyc2',
        component: () => import('../components/Kyc/Kyc2.vue')
      },
      {
        path: 'success',
        name: 'Kyc3',
        component: () => import('../components/Kyc/Kyc3.vue')
      }
    ]
  },
  {
    path: '/payment',
    name: 'DetailPembayaran',
    component: () => import('../components/Payment/DetailPembayaran.vue')
  },
  {
    path: '/list-business',
    name: 'ListStartup',
    component: () => import('../components/Startup/ListStartup.vue')
  },
  {
    path: '/detail-business',
    name: 'DetailProject',
    component: () => import('../components/Startup/DetailProject.vue')
  },
  {
    path: '/wh-detail-business',
    name: 'WhDetailProject',
    component: () => import('../components/Startup/WhDetailProject.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/pasar-sekunder',
    name: 'PasarSekunder',
    component: () => import('../views/PasarSekunderNew.vue')
  },
  {
    path: '/detail-bisnis',
    name: 'DetailBisnis',
    component: () => import('../views/DetailBisnis.vue')
  },
  {
    path: '/pasar-sekunder-detail',
    name: 'DetailPasarSekunder',
    component: () => import('../views/PasarSekunder.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

function parseJwt (token) {
  var base = token.split(' ')
  var base64Url = base[1].split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

function checkRoute (name) {
  if (name === 'Login') return false
  else if (name === 'Register') return false
  else if (name === 'contact-us') return false
  else if (name === 'FAQ') return false
  else if (name === 'Home') return false
  else if (name === 'PrivacyPolicy') return false
  else if (name === 'ForgotPassword') return false
  else if (name === 'ResetPassword') return false
  else if (name === 'VerifyAccount') return false
  else if (name === 'AboutUs') return false
  else if (name === 'ListStartup') return false
  else if (name === 'TermsAndConditions') return false
  else if (name === 'ErrorPageNotFound') return false
  else if (name === 'ErrorPageInvalid') return false
  else return true
}

router.beforeEach((to, from, next) => {
  if (VueCookies.isKey('user')) {
    const storage = VueCookies.get('user')
    const payload = parseJwt(storage)
    if (payload.exp < Date.now() / 1000) {
      store.dispatch('logout')
      localStorage.removeItem('user')
      localStorage.removeItem('bearerToken')
      VueCookies.keys().forEach(cookie => VueCookies.remove(cookie))
      if (checkRoute(to.name)) {
        next('/login')
      }
    } else {
      next()
    }
  } else {
    if (checkRoute(to.name)) {
      next('/login')
    } else {
      next()
    }
  }
})

export default router
